import Micromodal from 'micromodal';
import Fullpage from 'fullpage.js';
import Swiper, {
  Navigation,
  Pagination,
} from 'swiper';
import isMobile from 'is-mobile';
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import 'simplebar';
import './scrolloverflow';
import './cookies';
import './header-scroll';
import './swiper';
import './preloader';

if (module.hot) {
  module.hot.accept();
}

document.addEventListener('DOMContentLoaded', () => {
  Micromodal.init({
    onShow: (modal) => {
      disableBodyScroll(modal, {
        reserveScrollBarGap: true,
      });
      if (typeof fullpage_api !== 'undefined') {
        fullpage_api.setAllowScrolling(false);
      }
      const videos = modal.querySelectorAll('video')
      if (videos) {
        const [video] = videos;
        if (isMobile()) {
          if (typeof video.webkitEnterFullScreen === 'function') {
            video.webkitEnterFullScreen();
          } else if (typeof video.requestFullscreen === 'function') {
            video.requestFullscreen();
          }
        }
        if (video) {
          video.play();
        }
      }
    },
    onClose: (modal, el, event) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      enableBodyScroll(modal);
      modal.scrollTop = 0;
      if (typeof fullpage_api !== 'undefined') {
        fullpage_api.setAllowScrolling(true);
      }
      const videos = modal.querySelectorAll('video')
      videos.forEach((video) => {
        video.pause();
        video.currentTime = 0;
      })
    },
    openTrigger: 'data-modal-show',
    closeTrigger: 'data-modal-hide',
    openClass: 'shown',
  });
});

const head = document.getElementById('head');
const headPrepend = document.getElementById('head-prepend');
const headAppend = document.getElementById('head-append');
const onHeadAnimationEnd = () => {
  if (typeof fullpage_api !== 'undefined') {
    head.classList.remove('animate__animated', 'animate__slideOutUp');
    head.classList.add('hidden');
    head.removeEventListener('animationend', onHeadAnimationEnd);
  }
}

const fullpageBreakpoint = window.matchMedia('(min-width: 1024px)');
setTimeout(() => {
  if (fullpageBreakpoint.matches === true) {
    new Fullpage('#fullpage', {
      anchors: ['home', 'about', 'info', 'functional', 'share', 'order', 'control', 'guarantees'],
      licenseKey: 'D3E0240B-D7284040-8B21A0B4-D42107D7',
      scrollOverflow: true,
      normalScrollElements: '.modal',
      onLeave: (origin, destination, direction) => {
        if (destination.index === 0) {
          if (head) {
            head.addEventListener('animationend', onHeadAnimationEnd);
            head.classList.add('animate__animated', 'animate__slideOutUp');
          }
        }
        if (origin.index === 1 && direction === 'up') {
          if (head) {
            head.addEventListener('animationend', onHeadAnimationEnd);
            head.classList.add('animate__animated', 'animate__slideOutUp');
          }
        }
        if (destination.index === 1) {
          if (headPrepend && headAppend) {
            headPrepend.classList.add('bg-black-haze');
            headAppend.classList.add('bg-black-haze');
          }
        }
        if (destination.index === 2) {
          if (headPrepend && headAppend) {
            headPrepend.classList.remove('bg-black-haze');
            headAppend.classList.remove('bg-shark', 'bg-black-haze');
          }
        }
        if (origin.index === 2) {
          if (headPrepend && headAppend) {
            headPrepend.classList.add('bg-black-haze');
            headAppend.classList.add('bg-black-haze');
          }
        }
      },
      afterLoad: (origin, destination, direction) => {
        if (origin.index === 0 && direction === 'down') {
          if (head) {
            head.classList.remove('hidden');
            head.classList.add('animate__animated', 'animate__slideInDown');
          }
        }
      }
    });
  }
}, 1);

const moveDown = document.querySelectorAll('[data-move-down]');
moveDown.forEach((node) => {
  node.addEventListener('click', () => {
    if (typeof fullpage_api !== 'undefined') {
      fullpage_api.moveSectionDown();
    }
  });
});

Swiper.use([Navigation, Pagination]);
let swiper;
const breakpoint = window.matchMedia('(min-width: 768px)');
const enableSwiper = () => {
  swiper = new Swiper('.swiper-container', {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
}
const breakpointChecker = () => {
  if (breakpoint.matches === true) {
    if (swiper !== undefined) {
      swiper.destroy(true, true);
    }
    return;
  } else if (breakpoint.matches === false) {
    return enableSwiper();
  }
}
breakpoint.addListener(breakpointChecker);
breakpointChecker();

window.addEventListener('DOMContentLoaded', () => {
  const toTopList = document.querySelectorAll('[data-to-top]');
  toTopList.forEach((node) => {
    node.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }, false);
  setTimeout(() => {
    const sectionsList = document.querySelectorAll('[data-head-mode]');
    const HEADER_HEIGHT = 116;
    const scrollY = window.scrollY;
    const mobileHead = document.getElementById('mobile-head');
    if (!mobileHead) return null;
    const mobileHeadText = mobileHead.querySelector('[data-head-text]');
    const sectionsBoundsMap = Object.keys(sectionsList).map((key, index) => {
      const node = sectionsList[key];
      const offset = HEADER_HEIGHT / 2;
      const obj = {
        bounds: {
          y: node.getBoundingClientRect().y + scrollY - offset,
        },
        mode: node.dataset.headMode || 'dark',
      }
      return obj;
    });
    const reversed = sectionsBoundsMap.reverse();
    const calculateMobileHeader = () => {
      const localScrollY = window.scrollY;
      if (localScrollY < HEADER_HEIGHT * 0.7) {
        mobileHead.classList.remove('bg-shark');
        mobileHead.classList.remove('bg-black-haze');
        mobileHead.classList.add('bg-transparent');
        if (mobileHeadText) {
          mobileHeadText.classList.remove('text-fuscous');
          mobileHeadText.classList.add('text-black-haze');
        }
        return null;
      }
      const found = reversed.find((obj) => {
        return localScrollY > obj.bounds.y;
      });
      if (found) {
        if (found.mode === 'light') {
          mobileHead.classList.remove('bg-shark');
          mobileHead.classList.add('bg-black-haze');
          if (mobileHeadText) {
            mobileHeadText.classList.add('text-fuscous');
            mobileHeadText.classList.remove('text-black-haze');
          }
        } else {
          mobileHead.classList.add('bg-shark');
          mobileHead.classList.remove('bg-black-haze');
          if (mobileHeadText) {
            mobileHeadText.classList.remove('text-fuscous');
            mobileHeadText.classList.add('text-black-haze');
          }
        }
      }
    }
    window.addEventListener('scroll', () => {
      calculateMobileHeader();
    });
    calculateMobileHeader();
  }, 2000);
});

