(function(){
  let doc = document.documentElement;
  let w = window;
  let prevScroll = w.scrollY || doc.scrollTop;
  let curScroll;
  let direction = 0;
  let prevDirection = 0;
  let header = document.getElementById('mobile-head');
  const headerHeight = 105;
  
  let checkScroll = function() {
    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      direction = 2;
    }
    else if (curScroll < prevScroll) {
      direction = 1;
    }
    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }
    prevScroll = curScroll;
  };
  let toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > headerHeight) { 
      header.classList.add('-translate-y-full');
      prevDirection = direction;
    }
    else if (direction === 1) {
      header.classList.remove('-translate-y-full');
      prevDirection = direction;
    }
  };
  window.addEventListener('scroll', checkScroll);
})();