import Swiper, { Pagination, Navigation } from 'swiper';
Swiper.use([Pagination]);

document.addEventListener('DOMContentLoaded', () => {
  const commentsSwiper = new Swiper('.swiper-comments-container', {
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    loop: true,
    spaceBetween: 20,
  });
});
