const cookies = document.getElementById('cookies');
if (cookies) {
    const accept = cookies.querySelector('[data-cookie-accept]');
    const cookiesEnabled = +localStorage.getItem('cookies_enabled');
    if (!cookiesEnabled) {
        cookies.classList.add('flex');
        cookies.classList.remove('hidden');
    }
    if (accept) {
        accept.addEventListener('click', () => {
            localStorage.setItem('cookies_enabled', 1);
            cookies.classList.add('hidden');
            cookies.classList.remove('flex');
        })
    }
}